<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        A reaction occurs in aqueous solution with an equilibrium constant of
        <number-value :value="eqConst" />
        is carried out at a temperature of
        <number-value :value="temp" unit="^\circ\text{C}." />
      </p>

      <p class="mb-2">
        a) If the reaction quotient,
        <stemble-latex content="$\text{Q,}$" />
        is found to be
        <number-value :value="qRxn" />
        an hour after the reaction begins, which direction must the reaction proceed in order to
        reach equilibrium?
      </p>

      <v-radio-group v-model="inputs.rxnDirection" class="mb-n1 mt-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="pl-8 mb-1 mt-0"
          :value="option.value"
        >
          <template #label>
            <stemble-latex style="font-size: 14px" :content="`$\\text{${option.expression}}$`" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n2">
        b) Once the system reaches equilibrium, the solution is heated up to
        <stemble-latex content="$\text{60.0}^{\circ}\text{C.}$" />
        The system is found to form more
        <stemble-latex :content="`$\\text{${prodOrReact}}$`" />
        as a result. Is this an endothermic or exothermic reaction?
      </p>

      <v-radio-group v-model="inputs.endoExo" class="mb-n6" :disabled="!allowEditing">
        <v-radio
          v-for="option in options2"
          :key="option.value"
          class="pl-8 mb-1 mt-0"
          :value="option.value"
        >
          <template #label>
            <stemble-latex style="font-size: 14px" :content="`$\\text{${option.expression}}$`" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question370',
  components: {
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        rxnDirection: null,
        endoExo: null,
      },
      options1: [
        {
          expression: 'Forward - towards the products',
          value: 'forward',
        },
        {
          expression: 'Backward - towards the reactants',
          value: 'backward',
        },
        {
          expression: 'The reaction is already at equilibrium',
          value: 'neither',
        },
      ],
      options2: [
        {
          expression: 'Endothermic',
          value: 'endo',
        },
        {
          expression: 'Exothermic',
          value: 'exo',
        },
      ],
    };
  },
  computed: {
    eqConst() {
      return this.taskState.getValueBySymbol('eqConst').content;
    },
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
    qRxn() {
      return this.taskState.getValueBySymbol('qRxn').content;
    },
    PRvar() {
      return this.taskState.getValueBySymbol('PRvar').content;
    },
    prodOrReact() {
      if (this.PRvar.value === 1) {
        return 'reactants';
      } else if (this.PRvar.value === 2) {
        return 'products';
      } else {
        return 'ERROR';
      }
    },
  },
};
</script>
